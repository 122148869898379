import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './index.css';

export default function FormTextInput({
  inputRef,
  label,
  obfuscate,
  disableSubmitOnEnter = true,
  onEnter,
  className,
  onChange,
  multiline,
  ...props
}) {
  const textFieldProperties = { ...props };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      // For multiline fields
      if (multiline) {
        // Shift+Enter always adds a newline
        if (e.shiftKey) {
          return; // Let the default behavior (newline) happen
        }
        // Enter alone behavior
        if (disableSubmitOnEnter) {
          return; // Let the default behavior (newline) happen since submission is disabled
        }
        // If disableSubmitOnEnter is false, prevent newline and allow submission
        e.preventDefault();
      }
      
      // For single-line fields or multiline with disableSubmitOnEnter=false
      if (disableSubmitOnEnter) {
        e.preventDefault();
      }
      
      if (onEnter) {
        onEnter(e);
      }
    }
  };

  if (obfuscate) {
    textFieldProperties.type = 'password';
  }

  if (inputRef) {
    textFieldProperties.inputRef = inputRef;
  }

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={className}
    >
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        type="text"
        onKeyDown={onKeyDown}
        className="form-text-input-field"
        onChange={onChange}
        multiline={multiline}
        { ...textFieldProperties }
      />
    </Box>
  );
}