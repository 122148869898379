import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _, { join } from 'lodash';
import { v4 as uuid } from 'uuid';

import api from '../../modules/api';

const NodesContext = createContext();
export const NodesProvider = ({ children }) => {
  const navigate = useNavigate();
  const { nodeId } = useParams();
  
  const [nodes, setNodes] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [modifiedCurrentNode, setModifiedCurrentNode] = useState(null);
  const [areYouSureAboutDeletingNode, setAreYouSureAboutDeletingNode] = useState(false);

  const isNodeModified = !_.isEqual(currentNode, modifiedCurrentNode);

  function getAndSetAllNodes() {
    api.node.getAll()
      .then(nodes => {
        setNodes(nodes);
      });
  }

  useEffect(() => {
    getAndSetAllNodes();
  }, []);

  useEffect(() => {
    if (currentNode?.id !== modifiedCurrentNode?.id) {
      setModifiedCurrentNode(_.cloneDeep(currentNode));
    }
    // todo: check to see if there are unsaved changes before switching
  }, [currentNode, modifiedCurrentNode]);

  useEffect(() => {
    if (nodeId) {
      if (nodeId !== currentNode?.id && nodes?.length) {
        const node = nodes.find(n => n.id === nodeId);
        setCurrentNode(_.cloneDeep(node));

        // api.node.getEdgesForNode(nodeId)
        //   .then(edgesForNode => {

        //   });
      }
    }
    else {
      setCurrentNode(null);
    }
  }, [nodeId, nodes, currentNode]);

  const onResetButtonClicked = () => {
    setModifiedCurrentNode(_.cloneDeep(currentNode));
  };

  const onPropertyValueChanged = id => e => {
    console.log('property value changed', e);

    setModifiedCurrentNode(prev => {
      const newProps = [...prev.properties];
      const modifiedPropIndex = prev.properties.findIndex(p => p.id === id);
      newProps[modifiedPropIndex].value = e.target.value;

      return { ...prev, properties: newProps };
    });
  };

  const onPropertyNameChanged = id => e => {
    setModifiedCurrentNode(prev => {
      const newProps = [...prev.properties];
      const modifiedPropIndex = prev.properties.findIndex(p => p.id === id);
      newProps[modifiedPropIndex].name = e.target.value;

      return { ...prev, properties: newProps };
    });
  };

  const onNodeNameChanged = e => {
    setModifiedCurrentNode(prev => {
      return { ...prev, name: e.target.value };
    });
  };

  const onDeleteProperty = id => e => {
    setModifiedCurrentNode(prev => {
      const newProps = prev.properties.filter(p => p.id !== id);
      return { ...prev, properties: newProps };
    });
  };

  const onDeleteNode = () => {
    setAreYouSureAboutDeletingNode(true);
  };

  const deleteNode = () => {
    console.log('deleting node', currentNode);
    api.node.delete(currentNode.id)
      .then(() => {
        console.log('deleted node', currentNode.id)

        // setNodes(prevNodes => {
        //   return prevNodes.filter(n => n.id !== currentNode.id);
        // });

        // this is a stopgap. when we delete a node on the backend and that node has edges
        // then we also have to delete those edges. we should really have a system
        // on the backend that monitors changes to relationships and informs the frontend via sockets
        getAndSetAllNodes();

        navigate('/entities');
      });
  }

  const saveModifiedNode = () => {
    console.log('saving node');

    api.node.update(modifiedCurrentNode)
      .then(modifiedNode => {
        setCurrentNode(_.cloneDeep(modifiedNode));
        setModifiedCurrentNode(_.cloneDeep(modifiedNode));
        // setNodes(nodes => {
        //   const index = nodes.findIndex(n => n.id === modifiedNode.id);
        //   const updatedNodes = [...nodes];
        //   updatedNodes[index] = modifiedNode;
        //   return updatedNodes;
        // });

        // this is a stopgap. when we update nodes on the backend and those involve edge creation
        // or otherwise edge modification as it relates to linked entities, then we create the potential
        // for entities on the frontend to have inaccurate edge information. we should really have a system
        // on the backend that monitors changes to relationships and informs the frontend via sockets
        getAndSetAllNodes();
      });
  };

  const createNode = (nodeDetails = {}) => {
    const { name = 'untitled', properties = [], edges = [] } = nodeDetails;
    api.node.create({ name, properties, edges })
      .then(node => {

        console.log('created node', node);

        setNodes(nodes => ([
          node,
          ...nodes,
        ]));
        navigate(`/entities/${node.id}`);
      });
  };

  const addNodeProperty = () => {
    setModifiedCurrentNode(prev => ({
      ...prev,
      properties: [
        ...prev.properties,
        {
          id: uuid(),
          name: 'newPropertyName',
          value: 'new property value'
        },
      ],
    }));
  };

  const onAddNodeEdge = ({ nodeA, nodeB, type }) => {
    setModifiedCurrentNode(prev => {
      const newEdge = {
        id: uuid(),
        nodeA,
        nodeB,
        type,
        userId: prev.userId,
        properties: [],
        propertiesById: {},
      };

      const newEdgesToAdd = [...(prev.edgesToAdd || []), newEdge];
      const newEdgesAsNodeA = nodeA === prev.id
        ? [...prev.edgesAsNodeA, newEdge]
        : [...prev.edgesAsNodeA];
      const newEdgesAsNodeB = nodeB === prev.id
        ? [...prev.edgesAsNodeB, newEdge]
        : [...prev.edgesAsNodeB];

      return {
        ...prev,
        edgesToAdd: newEdgesToAdd,
        edgesAsNodeA: newEdgesAsNodeA,
        edgesAsNodeB: newEdgesAsNodeB,
      };
    });
  }

  const onDeleteEdge = edgeId => {
    console.log('deleting edge', edgeId);
    console.log('modifiedCurrentNode at time of deletion', modifiedCurrentNode);

    setModifiedCurrentNode(prev => {
      const newEdgesToDelete = [...(prev.edgesToDelete || [])];

      const newEdgesToAdd = [...(prev.edgesToAdd || [])];
      const edgesToAddIndex = newEdgesToAdd.findIndex(e => e.id === edgeId);
      if (edgesToAddIndex > -1) {
        newEdgesToAdd.splice(edgesToAddIndex, 1);
      }
      else {
        newEdgesToDelete.push({ id: edgeId });
      }

      const edgesAsNodeAIndex = prev.edgesAsNodeA.findIndex(e => e.id === edgeId);
      const newEdgesAsNodeA = [...prev.edgesAsNodeA];
      if (edgesAsNodeAIndex > -1) {
        newEdgesAsNodeA.splice(edgesAsNodeAIndex, 1);
      }

      const edgesAsNodeBIndex = prev.edgesAsNodeB.findIndex(e => e.id === edgeId);
      const newEdgesAsNodeB = [...prev.edgesAsNodeB];
      if (edgesAsNodeBIndex > -1) {
        newEdgesAsNodeB.splice(edgesAsNodeBIndex, 1);
      }

      return {
        ...prev,
        edgesToAdd: newEdgesToAdd,
        edgesToDelete: newEdgesToDelete,
        edgesAsNodeA: newEdgesAsNodeA,
        edgesAsNodeB: newEdgesAsNodeB,
      };
    });
  }

  // const deleteEdge = (edgeId) => {
  //   api.edge.delete(edgeId)
  //     .then(() => {
  //       // this is a stopgap. local nodes are configured to list their edges, which we
  //       // need to either update manually, or via some automatic protocol, or like this
  //       getAndSetAllNodes();
  //     });
  // }

  const nodesById = useMemo(() => {
    return _.keyBy(nodes, 'id');
  }, [nodes])

  const nodesGroupedByNodeB= useMemo(() => {
    if (!nodes) return {};

    return nodes.reduce((grouped, node) => {
      const { edgesAsNodeB } = node;

      edgesAsNodeB.forEach(edge => {
        if (!grouped[edge.type]) {
          grouped[edge.type] = {};
        }
        if (!grouped[edge.type][nodesById[edge.nodeB].name]) {
          grouped[edge.type][nodesById[edge.nodeB].name] = [];
        }
        grouped[edge.type][nodesById[edge.nodeB].name].push(nodesById[edge.nodeA]);
      });

      return grouped;
    }, {});
  }, [nodes, nodesById]);

  const getAllNodesMatchingString = async (str) => {
    // todo: this should cal the server, it currently assumes all nodes are here in the context already
    return nodes.filter(node => 
      JSON.stringify(node)
        .toLowerCase()
        .includes(str.toLowerCase())
    );
  };

  console.log('nodesGroupedByNodeB', nodesGroupedByNodeB);
  console.log('currentNode', currentNode);
  console.log('modifiedCurrentNode', modifiedCurrentNode);

  const context = {
    nodes,
    nodeId,
    nodesById,
    currentNode,
    modifiedCurrentNode,
    areYouSureAboutDeletingNode,
    isNodeModified,
    nodesGroupedByNodeB,
  
    onDeleteEdge,
    onAddNodeEdge,
    onResetButtonClicked,
    onPropertyValueChanged,
    onPropertyNameChanged,
    onNodeNameChanged,
    onDeleteProperty,
    onDeleteNode,
    deleteNode,
    saveModifiedNode,
    createNode,
    addNodeProperty,
    setAreYouSureAboutDeletingNode,
    getAllNodesMatchingString,
  };
  console.log('nodes context', context);

  return (
    <NodesContext.Provider value={ context }>
      { children }
    </NodesContext.Provider>
  );
};

export const useNodesContext = () => {
  const context = useContext(NodesContext)
  if (context === undefined) {
    throw new Error(
      'UseNodesContext must be used within a NodesProvider',
    )
  }
  return context;
}
