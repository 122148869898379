import React from 'react';
import Button from '@mui/material/Button';

import NodeProperties from './NodeProperties';
import NodeEdges from './NodeEdges';
import NodesList from './NodesList';
import FormTextInput from '../../sharedComponents/FormTextInput';
import { useNodesContext, NodesProvider } from './context';

import './index.scss';

function NodesContainer() {
  return (
    <NodesProvider>
      <Nodes />
    </NodesProvider>
  );
}

function Nodes() {
  const {
    nodes,
    nodeId,
    currentNode,
    modifiedCurrentNode,
    areYouSureAboutDeletingNode,
    isNodeModified,
  
    onResetButtonClicked,
    onNodeNameChanged,
    onDeleteNode,
    deleteNode,
    saveModifiedNode,
    setAreYouSureAboutDeletingNode,
  } = useNodesContext();

  if (!nodes?.length) return null;

  return (
    <div className="nodes-container">
      <NodesList />

      <div className="node-container">
        { nodeId && !currentNode 
          ? (<p>Error -- no node for ID</p>)
          : null
        }
        { currentNode && modifiedCurrentNode && (modifiedCurrentNode?.id === currentNode?.id)
          ? (
            <div>
              <div className="node-title">
                <h3>
                  <FormTextInput
                    value={ modifiedCurrentNode.name }
                    onChange={ onNodeNameChanged }
                    multiline
                    minRows="1"
                    fullWidth
                    className="node-name-input"
                    label={ currentNode.name }
                  />
                  {/* { currentNode.name } */}
                  { isNodeModified ? ' - MODIFIED' : null }
                </h3>
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={ saveModifiedNode }
                    disabled={ !isNodeModified }
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={ onResetButtonClicked }
                    disabled={ !isNodeModified }
                  >
                    Reset
                  </Button>
                  {
                    areYouSureAboutDeletingNode
                      ? (
                        <div>
                          <Button
                            color="secondary"
                            variant="outlined"
                            size="small"
                            onClick={ () => setAreYouSureAboutDeletingNode(false) }
                          >
                            Cancel
                          </Button>
                          <Button
                            color="error"
                            size="small"
                            onClick={ deleteNode }
                          >
                            Yes, delete
                          </Button>
                        </div>
                      )
                      : (
                        <Button
                          color="error"
                          variant="outlined"
                          size="small"
                          onClick={ onDeleteNode }
                        >
                          Delete
                        </Button>
                      )
                  }
                </>
              </div>

              <NodeProperties />
              <NodeEdges />
            </div>
          )
          : null
        }
      </div>
    </div>
  );
}

export default NodesContainer;