import { useState } from 'react';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';

import Navigation from '../../../sharedComponents/Navigation';
import { useNodesContext } from '../context';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import './index.scss';

const ALL_ENTITIES = 'all entities';
const ENTITIES_BY_EDGES = 'entities by edges';

function NodesList() {
  const {
    nodes,
    createNode,
  } = useNodesContext();

  const [displayType, setDisplayType] = useState(ENTITIES_BY_EDGES);

  console.log('NodesList nodes', nodes);

  return (
    <div className="display-nodes">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="entity-display-type-select-label">Entity Display Type</InputLabel>
          <Select
            labelId="entity-display-type-select-label"
            id="entity-display-type-select"
            value={displayType}
            label="Entity Display Type"
            onChange={e => setDisplayType(e.target.value)}
          >
            <MenuItem value={ ALL_ENTITIES }>{ ALL_ENTITIES }</MenuItem>
            <MenuItem value={ ENTITIES_BY_EDGES }>{ ENTITIES_BY_EDGES }</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <div>
        <Navigation
          title={ displayType }
          rightSectionComponent={ () => {
            return (
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="new node"
                  onClick={ () => createNode() }
                >
                  <AddCircleIcon
                    color="primary"
                    aria-label="new node"
                  />
                </IconButton>
              </Stack>
            );
          } }
          leftSectionComponent={ () => {
            return null;
          } }
        />

        { displayType === ALL_ENTITIES && (
          <AllNodesList />
        ) }

        { displayType === ENTITIES_BY_EDGES && (
          <NodesByEdgesList />
        ) }
      </div>
    </div>
  );
}

function AllNodesList() {
  const { nodes } = useNodesContext();
  return (
    <div className="nodes-list">
      { nodes.map(node => {
        return (
          <div className="node-name" key={ node.id }>
            <Link to={ `/entities/${node.id}` }>
              { node.name }
            </Link>
          </div>
        );
      }) }
    </div>
  );
}

function NodesByEdgesList() {
  const { nodesGroupedByNodeB } = useNodesContext();
  return (
    <div className="nodes-by-edges-container">
      { Object.entries(nodesGroupedByNodeB).map(([typeName, nodeBEntities]) => {
        return (
          <>
            <div className="type-name">
              { typeName }
            </div>
            <div className="nodes-for-edge-type">
              { Object.entries(nodeBEntities).map(([nodeBName, entities]) => {
                return (
                  <>
                    <div className="node-b-name">
                      { nodeBName }
                    </div>
                    <div className="nodes-for-node-b-and-edge-type">
                      { entities.map(entity => {
                        return (
                          <div key={ entity.id }>
                            <Link to={ `/entities/${entity.id}` }>
                              { entity.name }
                            </Link>
                          </div>
                        );
                      }) }
                    </div>
                  </>
                );
              }) }
            </div>
          </>
        );
      }) }
    </div>
  );
}

export default NodesList;
