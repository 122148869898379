import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../../state/providers/auth';
import FormTextInput from '../../../sharedComponents/FormTextInput';
import ErrorBox from '../../../sharedComponents/ErrorBox';

import './index.scss';

export default function Login() {
  const [submitting, setSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { login, authResolving, isLoggedIn } = useAuthContext();

  const allFieldsValid = loginInfo.username && loginInfo.password;

  const submissionDisabled = submitting || !allFieldsValid;

  useEffect(() => {
    if (isLoggedIn && !authResolving) {
      navigate(`/`);
    }
  }, [authResolving, isLoggedIn]);

  const onUsernameChange = (e) => {
    setError(null);
    setLoginInfo({
      ...loginInfo,
      username: e.target.value
    });
  };

  const onPasswordChange = (e) => {
    setError(null);
    setLoginInfo({
      ...loginInfo,
      password: e.target.value
    });
  };

  const submitLoginForm = async (e) => {

    e.preventDefault();
    if (submissionDisabled) return;

    setSubmitting(true)
    if (allFieldsValid) {
      try {
        await login(loginInfo);
      }
      catch (err) {
        setError('Unknown error. Please check your email/password and try again.');
      }
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h2>Login</h2>

      <FormTextInput
        label={ 'Username' }
        type="text"
        onChange={ onUsernameChange }
        onEnter={ submitLoginForm }
        className="login-username-input"
        error={ error }
        name="username"
        autocomplete="username"
      />
      <FormTextInput
        obfuscate
        label={ 'Password' }
        onChange={ onPasswordChange }
        onEnter={ submitLoginForm }
        error={ error }
        name="password"
        autocomplete="password"
      />

      <ErrorBox error={ error } />

      <div className="login-section-footer">
        <Button
          disabled={ submissionDisabled }
          onClick={ submitLoginForm }
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Login
        </Button>
      </div>
    </div>
  );
};