import { Link } from 'react-router-dom';

export default function Landing() {
  return (
    <div>
      <h1>Welcome to EntityBox (eb)</h1>

      <p>eb is how you:</p>
      <ul>
        <li>organize the data of your life. it start by keeping it all in one place. this platform is built to handle all data</li>
        <li>develop a basis on which to compound your growth</li>
        <li>merge new information with an existing corpus of your life's data (concepts, people, code, etc)</li>
        <li>build meaningful apps (lightning fast!), and a lot of them!</li>
        <li>keep your projects organized</li>
        <li>leverage the power behind popular technologies like Wolfram Language (symbolic, operable data)</li>
      </ul>

      <p>For a good example of what you can do with eb, see Jacq's profile:&nbsp;
        <Link to="/user/jdebeer">Jacq's Profile</Link>
      </p>

      <p>Unfortunately, we're not letting any more folks create accounts for the time being. Please email <a href="mailto:me@ajdebeer.com">me@ajdebeer.com</a> to join the waitlist. Can't wait to hear from you!</p>
    </div>
  )
}