import { useMemo } from 'react';

import moment from 'moment';

import Loading from '../../../sharedComponents/Loading';
import TypingIndicator from '../../../sharedComponents/TypingIndicator';
import ChatMessage from '../ChatMessage';

import { useChatContext } from '../context.js';

import { CHAT_WINDOW_MAX_WIDTH_PX } from '../../../config';

import './index.scss';

function ChatMessages() {
  const {
    initializing,

    messages,
    systemTyping,

    messagesContainerRef,
  } = useChatContext();

  const messagesByThread = useMemo(() => {

    console.log('getting messages by thread', messages);

    const messagesByThreadId = messages.reduce((byThreadId, message) => {
      if (!byThreadId[message.thread.id]) {
        byThreadId[message.thread.id] = {
          thread: message.thread,
          messages: [message],
          earliestMessageCreatedAt: message.createdAt,
        };
      }
      else {
        byThreadId[message.thread.id].messages.push(message);
        if (moment(byThreadId[message.thread.id].earliestMessageCreatedAt).isAfter(moment(message.createdAt))) {
          byThreadId[message.thread.id].earliestMessageCreatedAt = message.createdAt;
        }
      }
      return byThreadId;
    }, {});
    const byThread = Object.values(messagesByThreadId).sort((a, b) => {
      if (moment(a.earliestMessageCreatedAt).isAfter(moment(b.earliestMessageCreatedAt))) {
        return 1;
      }
      return -1;
    })
    byThread.forEach(threadMessages => {
      threadMessages.messages = threadMessages.messages.sort((a, b) => {
        if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
          return 1;
        }
        return -1;
      })
    })
    return byThread;
  }, [messages]);


  return (
    <div
      className="chat-messages-body"
      style={{ maxWidth: `${CHAT_WINDOW_MAX_WIDTH_PX}px` }}
      ref={ messagesContainerRef }
    >
      { initializing
        ? (<Loading />)
        : (
            <div className="chat-messages-body-inner">
              { messagesByThread.map(thread => {
                return (
                  <>
                    <div>
                      <div>Thread ID: { thread.thread.id }</div>
                      <div>Thread Name: { thread.thread.name }</div>
                      <div>Thread Intent: { thread.thread.propertiesByName.threadIntent.value }</div>
                    </div>
                    { 
                      thread.messages.map(message => {
                        return (
                          <ChatMessage
                            key={ message.id }
                            message={ message }
                          />
                        );
                      }) 
                    }
                  </>
                );
              })}
              {
                systemTyping
                  ? (
                    <div className="chat-message-container system">
                      <div className="chat-message-inner">
                        <TypingIndicator />
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
        )
      }
    </div>
  );
}

export default ChatMessages;
