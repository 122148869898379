import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../../modules/api';
import { socket } from '../../socket';
import { useNavigate } from 'react-router-dom';

const parseJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const AuthContext = createContext();
export const AuthProvider = ({ children, token, resetToken }) => {
  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [rootUser, setRootUser] = useState(null);
  const [initialAuthLoaded, setInitialAuthLoaded] = useState(false);

  const login = async ({ username, password }) => {
    const res = await api.auth.login({ username, password });
    if (res.error) throw new Error(res.error);
    if (res.token) resetToken(res.token);
  };

  const logout = () => {
    resetToken(null);
    setRootUser(null);
    socket.disconnect();

    localStorage.clear();
    // todo: reloading the site after logout is a little hacky & clunky -- we should have all user-related state
    //       reset via hooks that monitor the current user
    navigate(0); 
  };

  const getAndSetUserInformation = async () => {
    const user = await api.auth.getUserInformation()
    setUser(user);
  };


  useEffect(() => {
    function onConnect() {
      console.log('socket connected', socket.id);
      setIsSocketConnected(true);
    }
    function onDisconnect() {
      console.log('socket disconnected', socket.id);
      setIsSocketConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);


  // initial effect
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    console.log('got stored token', storedToken);
    if (storedToken && !token) {
      console.log('logging in with stored token');
      api.auth.login({ token: storedToken })
        .then(res => {
          console.log('resetting token', res);
          resetToken(res.token);
        })
        .catch((err) => {
          console.log('error logging in with existing token', err);
          logout();
        })
        .finally(() => {
          console.log('finished logging in with existing token. setting initial auth loaded')
          setInitialAuthLoaded(true);
        });
    }
    else {
      setInitialAuthLoaded(true);
    }
  }, []);

  // effect when token changes
  useEffect(() => {
    if (token && (!rootUser || rootUser.token !== token)) {
      try {
        const payload = parseJWT(token);
        setRootUser({
          token,
          userId: payload.userId,
        });

        if (!payload.passwordResetRequired) {
          getAndSetUserInformation();
          socket.io.opts.query = { userId: payload.userId };
          socket.connect();
        }
        setInitialAuthLoaded(true);
      }
      catch(err) {
        console.error(err);
      }
    }
  }, [token]);

  const authResolving = (rootUser?.token !== token && (token || rootUser?.token)) || !initialAuthLoaded;
  const isLoggedIn = !!(token && !authResolving); // not logged out but maybe not fully registered, but possibly fully registered

  const context = {
    user,
    socket,
    isLoggedIn,
    authResolving,
    isSocketConnected,

    login,
    logout,
  };

  console.log('auth context', context);

  return (
    <AuthContext.Provider value={ context }>
      { children }
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(
      'UseAuthContext must be used within a AuthProvider',
    )
  }
  return context
}
