import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import anna from './lost-gifs/anna.gif';
import gravy from './lost-gifs/gravy.gif';
import jumanji from './lost-gifs/jumanji.gif';
import kermit from './lost-gifs/kermit.gif';
import marc from './lost-gifs/marc.gif';
import marky from './lost-gifs/marky.gif';
import reilly from './lost-gifs/reilly.gif';
import ryan from './lost-gifs/ryan.gif';
import vincent from './lost-gifs/vincent.gif';

import jim from './lost-gifs/jim.webp';
import harry from './lost-gifs/harry.webp';
import nope from './lost-gifs/nope.webp';
import joaquin from './lost-gifs/joaquin.webp';

import './index.css';

const lostGifs = [joaquin, nope, harry, jim, anna, gravy, jumanji, kermit, marc, marky, reilly, ryan, vincent];

export default function NotFoundPage() {
  const [gifIndex, setGifIndex] = useState();

  useEffect(() => {
    const randomIndex = _.random(0, lostGifs.length - 1, false);
    setGifIndex(randomIndex)
  }, []);

  if (_.isNil(gifIndex)) return null;

  return (
    <div className="lost-page">
      <div className="lost-page-content">
      <h1>Page Not Found</h1>
        <img
          src={ lostGifs[gifIndex] }
          alt="Page Cannot Be Found"
          className="lost-gif"
        />
      </div>
    </div>
  );
};