import { useState, useEffect, useCallback } from 'react';
import { TextField, Autocomplete, Button } from '@mui/material';
import { useNodesContext } from '../../context';

// Custom hook for debouncing
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

const EDGE_TYPES = [
  'is an instance of',
  'is in the category of',
  'belongs to',
  'is a message in',
  'is a post in',
  'is api for',
];

const EdgeAdder = ({
  onTypeChange,
  onNodeChange,
  nodeType,
  type,
  nodeId,
  onAddClicked,
}) => {
  const { getAllNodesMatchingString } = useNodesContext();
  const [nodeOptions, setNodeOptions] = useState([]);
  const [nodeInput, setNodeInput] = useState('');
  const debouncedNodeInput = useDebounce(nodeInput, 100);

  // Find the selected node object based on ID
  const selectedNode = nodeOptions.find(node => node.id === nodeId) || null;

  // Fetch nodes with debounced input
  const fetchNodes = useCallback(async (input) => {
    if (input.length > 0) {
      try {
        const nodes = await getAllNodesMatchingString(input);
        setNodeOptions(nodes);
      } catch (error) {
        console.error('Error fetching nodes:', error);
        setNodeOptions([]);
      }
    } else {
      setNodeOptions([]);
    }
  }, [getAllNodesMatchingString]);

  useEffect(() => {
    fetchNodes(debouncedNodeInput);
  }, [debouncedNodeInput, fetchNodes]);

  return (
    <div className="edge-container" style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
      <Autocomplete
        options={EDGE_TYPES}
        value={type}
        onChange={(event, newValue) => {
          onTypeChange({ target: { value: newValue || '' } });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Type"
            variant="outlined"
            sx={{ minWidth: 200 }}
          />
        )}
        freeSolo={false}
        disableClearable
      />
      
      <Autocomplete
        options={nodeOptions}
        value={selectedNode}
        getOptionLabel={(option) => option?.name || ''} // Display node name
        onChange={(event, newValue) => {
          // Pass the ID to parent component
          onNodeChange({ target: { value: newValue?.id || '' } });
          // Reset input if cleared
          if (!newValue) setNodeInput('');
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'input') {
            setNodeInput(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={nodeType}
            variant="outlined"
            sx={{ minWidth: 300 }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        freeSolo={false} // Changed to false since we want to force selection from options
        loading={debouncedNodeInput.length > 0 && nodeOptions.length === 0}
      />
      
      <Button 
        variant="contained" 
        onClick={onAddClicked}
        sx={{ height: 'fit-content' }}
      >
        Add
      </Button>
    </div>
  );
};

export default EdgeAdder;