export default function nodeApi(axios, serverUrl) {
  return {
    getAll: async () => {
      const { data: { nodes } } = await axios({
        method: 'get',
        url: `${serverUrl}/eb/node`,
      });
      return nodes;
    },
    create: async ({ name, properties, edges }) => {
      const { data: { node } } = await axios({
        method: 'post',
        url: `${serverUrl}/eb/node`,
        data: { name, properties, edges },
      });
      return node;
    },
    update: async (modifiedNode) => {
      const { data: { node } } = await axios({
        method: 'patch',
        url: `${serverUrl}/eb/node`,
        data: { ...modifiedNode },
      });
      return node;
    },
    delete: async (nodeId) => {
      const { data: { node } } = await axios({
        method: 'delete',
        url: `${serverUrl}/eb/node/${nodeId}`,
      });
      return node;
    }
  };
}
