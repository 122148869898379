import { useState } from 'react';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';

import FormTextInput from '../../../sharedComponents/FormTextInput';
import { useNodesContext } from '../context';

import './index.scss';

function NodeProperties() {
  const {
    modifiedCurrentNode,
    onPropertyValueChanged,
    onPropertyNameChanged,
    onDeleteProperty,
    addNodeProperty,
  } = useNodesContext();

  const [optionButtonElement, setOptionButtonElement] = useState(null);

  const handleClick = (event) => {
    setOptionButtonElement(event.currentTarget);
  };

  const handleClose = () => {
    setOptionButtonElement(null);
  };

  return (
    <>
      <div className="properties-header">
        <h4>Properties:</h4>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <IconButton
            color="primary"
            aria-label="new node property"
            onClick={ addNodeProperty }
          >
            <AddCircleIcon
              color="primary"
              aria-label="new node property"
            />
          </IconButton>
        </Stack>
      </div>
      <div className="node-properties">
        { modifiedCurrentNode.properties.map((property) => {
          const { id, name, value } = property;
          const modifiedProperty = modifiedCurrentNode.properties.find(p => p.id === id);

          const {
            value: modifiedPropertyValue,
            name: modifiedPropertyName,
          } = modifiedProperty;
            
          return (
            <div key={id} className="node-property">
              <div className="node-property-name">
                <FormTextInput
                  value={ modifiedPropertyName }
                  onChange={ onPropertyNameChanged(id) }
                  multiline
                  minRows="1"
                  fullWidth
                  className="node-property-name-input"
                  label={ name }
                />
              </div>
              <div className="node-property-value">
                <FormTextInput
                  value={ modifiedPropertyValue }
                  onChange={ onPropertyValueChanged(id) }
                  multiline
                  minRows="1"
                  fullWidth
                  className="node-property-value-input"
                  label={ modifiedPropertyName }
                />
              </div>
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  size="small"
                  variant="outlined"
                >
                  options
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={optionButtonElement}
                  open={Boolean(optionButtonElement)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={onDeleteProperty(id)}>delete</MenuItem>
                </Menu>
              </div>
            </div>
          );
        }) }
      </div>
    </>
  );
}

export default NodeProperties;
