import { Routes, Route, Link } from 'react-router-dom';

import { useAuthContext } from '../../state/providers/auth';
import Navigation from '../../sharedComponents/Navigation';

import Chat from '../Chat';
import Nodes from '../Nodes';
import User from '../User';
import Login from '../Auth/Login';
// import CreateAccount from '../Auth/CreateAccount';
import Landing from '../Landing';
import NotFoundPage from '../NotFoundPage';

import './index.scss';

function Main() {
  const { user, isLoggedIn, authResolving } = useAuthContext();

  if (authResolving) return null;

  return (
    <div className="main-view-container">
      <div className="main-view-inner">
        {
            isLoggedIn
            ? (
                <Navigation
                  title="EntityBox"
                  leftSectionComponent={ () => {
                    return (
                      <>
                        <Link to="/entities">
                          Entities
                        </Link>
                        <Link to="/">
                          Chat
                        </Link>
                        <Link to={ `/user/${user?.username}` } >
                          Profile
                        </Link>
                      </>
                    );
                  } }
                  rightSectionComponent={ () => {
                    return null;
                  } } 
                />
              )
            : (
                <></>
              )
        }
        <Routes>
          <Route
            path="/user/:username"
            element={
              <User />
            }
          />
          <Route
            path="/auth/login"
            element={
              <Login />
            }
          />
          {/** Registration is disabled! (on the backend too, don't get any ideas!) */}
          {/* <Route
            path="/auth/register"
            element={
              <CreateAccount />
            }
          /> */}
          {
            isLoggedIn
              ? (
                  <>
                    <Route
                      path="/"
                      element={
                        <Chat />
                      }
                    />
                    <Route
                      path="/entities"
                      element={
                        <Nodes />
                      }
                    />
                    <Route
                      path="/entities/:nodeId"
                      element={
                        <Nodes />
                      }
                    />
                  </>
                )
              : (
                  <>
                    <Route
                      path="/"
                      element={
                        <Landing />
                      }
                    />
                  </>
                )
          }
          <Route
            path="/*"
            element={ <NotFoundPage /> }
          />
        </Routes>
      </div>
    </div>
  );
}

export default Main;
