export default function chatMessageApi(axios, serverUrl) {
  return {
    getAll: async () => {
      const { data: { messages } } = await axios({
        method: 'get',
        url: `${serverUrl}/eb/chat/message`,
      });
      return messages;
    },
    create: async ({ text }) => {
      const { data: { message } } = await axios({
        method: 'post',
        url: `${serverUrl}/eb/chat/message`,
        data: { text },
      });
      return message;
    }
  };
}
