import FormTextInput from '../../../sharedComponents/FormTextInput';
import ErrorBox from '../../../sharedComponents/ErrorBox';

import { useChatContext } from '../context.js';
import { CHAT_WINDOW_MAX_WIDTH_PX } from '../../../config';

import './index.scss';

function ChatInput() {
  const {
    errorMessage,

    messageBody,
    inputDisabled,
    submissionDisabled,

    messageInputFieldRef,

    setMessageBody,
    submitMessage,
    handleMessageBodyChange,
  } = useChatContext();
  
  const onInputFieldEnter = async (e) => {
    e.preventDefault();
    if (!e.shiftKey) {
      if (submissionDisabled) return;
      await submitMessage(messageBody);
    }
    else {
      // user did a shift+enter => interpret it as a carriage return
      setMessageBody(body => body+'\n');
    }
  };

  return (
    <div
      className="chat-input-container"
      style={{ maxWidth: `${CHAT_WINDOW_MAX_WIDTH_PX}px` }}
    >
      <FormTextInput
        inputRef={ messageInputFieldRef }
        value={ messageBody }
        onChange={ handleMessageBodyChange }
        multiline
        minRows="2"
        maxRows="20"
        fullWidth
        className="compose-message-body"
        onEnter={ onInputFieldEnter }
        disabled={ inputDisabled }
        // label="Message Body"
      />
      <ErrorBox error={ errorMessage } />
    </div>
  );
}

export default ChatInput;