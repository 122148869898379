import './index.scss';

export default function ErrorBox({ children, error, show = true }) {
  if (error & children) {
    console.warn('ignoring "error" param in ErrorBox as children are available');
  }
  if (!show || (!error && !children)) return null;
  return (
    <div className="error-box-container">
      <div className="error-box-inner">
        { error ? error : children }
      </div>
    </div>
  );
};
