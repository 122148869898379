import { useState } from 'react';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import { useNodesContext } from '../context';

import EdgeAdder from './EdgeAdder';

import './index.scss';

function NodeEdges() {
  const {
    nodesById,
    currentNode,
    modifiedCurrentNode,
    onDeleteEdge,
    
    onAddNodeEdge,
  } = useNodesContext();

  const [addingEdge, setAddingEdge] = useState(false);
  const [edgeBeingAdded, setEdgeBeingAdded] = useState({});


  const onAddEdgeClicked = (edge) => {
    setAddingEdge(true);
    setEdgeBeingAdded({ ...edge });
  };

  const onEdgeFieldChange = field => e => {
    setEdgeBeingAdded(current => ({
      ...current,
      [field]: e.target.value,
    }));
  };

  const onAddClicked = () => {
    onAddNodeEdge(edgeBeingAdded);
    setAddingEdge(false);
    setEdgeBeingAdded({});
  };

  return (
    <>
      <div className="node-edges">
        <div>
          <div className="edges-as-node-a-header">
            <h3>Edges As Node A:</h3>
            { !addingEdge && (
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="new node edge"
                  onClick={ () => onAddEdgeClicked({ nodeA: currentNode.id, id: currentNode.id }) }
                >
                  <AddCircleIcon
                    color="primary"
                    aria-label="new node edge"
                  />
                </IconButton>
              </Stack>
            ) }
          </div>
          <div className="edges-as-node-a-list">
            {
              modifiedCurrentNode.edgesAsNodeA.map((edge) => {
                return (
                  <div className="edge-container" key={edge.id}>
                    <div>type: {edge.type}</div>
                    <div>
                      Node B:
                      <Link to={ `/entities/${edge.nodeB}` }>
                        {nodesById[edge.nodeB].name}
                      </Link>
                    </div>
                    <OptionsButton onDelete={ onDeleteEdge } edgeId={ edge.id } />
                  </div>
                );
              })
            }
            { addingEdge && edgeBeingAdded.nodeA === currentNode.id && (
              <EdgeAdder
                onTypeChange={ onEdgeFieldChange('type') }
                onNodeChange={ onEdgeFieldChange('nodeB') }
                nodeType="Node B"
                type={ edgeBeingAdded.type }
                nodeId={ edgeBeingAdded.nodeB }
                onAddClicked={ onAddClicked }
              />
            ) }
          </div>
        </div>
        <div>
          <div className="edges-as-node-b-header">
            <h3>Edges As Node B:</h3>
            { !addingEdge && (
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="new node edge"
                  onClick={ () => onAddEdgeClicked({ nodeB: currentNode.id, id: currentNode.id }) }
                >
                  <AddCircleIcon
                    color="primary"
                    aria-label="new node edge"
                  />
                </IconButton>
              </Stack>
            ) }
          </div>
          <div className="edges-as-node-b-list">
            {
              modifiedCurrentNode.edgesAsNodeB.map((edge) => {
                return (
                  <div className="edge-container" key={ edge.id }>
                    <div>
                      Node A:
                      <Link to={ `/entities/${edge.nodeA}` }>
                        {nodesById[edge.nodeA].name}
                      </Link>
                    </div>
                    <div>type: {edge.type}</div>
                    <OptionsButton onDelete={ onDeleteEdge } edgeId={ edge.id } />
                  </div>
                );
              })
            }
            { addingEdge && edgeBeingAdded.nodeB === currentNode.id && (
              <EdgeAdder
                onTypeChange={ onEdgeFieldChange('type') }
                onNodeChange={ onEdgeFieldChange('nodeA') }
                nodeType="Node A"
                type={ edgeBeingAdded.type }
                nodeId={ edgeBeingAdded.nodeA }
                onAddClicked={ onAddClicked }
              />
            ) }
          </div>
        </div>
      </div>
    </>
  );
}

const OptionsButton = ({
  onDelete,
  edgeId
}) => {
  const [optionButtonElement, setOptionButtonElement] = useState(null);

  const handleOptionButtonClick = (event) => {
    setOptionButtonElement(event.currentTarget);
  };

  const handleOptionsButtonClose = () => {
    setOptionButtonElement(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOptionButtonClick}
        size="small"
        variant="outlined"
      >
        options
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={optionButtonElement}
        open={Boolean(optionButtonElement)}
        onClose={handleOptionsButtonClose}
      >
        <MenuItem onClick={() => onDelete(edgeId)}>delete</MenuItem>
      </Menu>
    </div>
  );
}

export default NodeEdges;
