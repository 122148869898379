export default function authApi(axios, serverUrl) {
  return {
    login: async ({ username, password, token }) => {
      const { data: authResponse } = await axios({
        method: 'post',
        url: `${serverUrl}/auth/login`,
        data: {
          username, password, token
        }
      });
      return authResponse;
    },
    // register: async ({
    //   username,
    //   password,
    //   firstName,
    //   lastName,
    // }) => {
    //   const { data: registerResponse } = await axios({
    //     method: 'post',
    //     url: `${serverUrl}/auth/register`,
    //     data: {
    //       username,
    //       password,
    //       firstName,
    //       lastName,
    //     }
    //   });
    //   return registerResponse;
    // },
    getUserInformation: async () => {
      const { data } = await axios({
        method: 'get',
        url: `${serverUrl}/auth/user`,
        data: {}
      });
      return data;
    },
  };
};