import axios from 'axios';

import chatMessageRoutes from './chat/message';
import nodeRoutes from './node';
import authRoutes from './auth';

import { SERVER_URL } from '../../config';

function axiosRequestInterceptor(config) {
  const token = localStorage.getItem('token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
}

function axiosRequestErrorInterceptor(error) {
  console.log('there was an error sending the axios request');
  console.error(error);
  return Promise.reject(error);
}

axios.interceptors.request.use(axiosRequestInterceptor, axiosRequestErrorInterceptor);

const api = {
  chat: {
    message: chatMessageRoutes(axios, SERVER_URL),
  },
  node: nodeRoutes(axios, SERVER_URL),
  auth: authRoutes(axios, SERVER_URL),
};

export default api;
