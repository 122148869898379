import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

import api from '../../modules/api';
import { useAuthContext } from '../../state/providers/auth';

const ChatContext = createContext();
export const ChatProvider = ({ children }) => {
  const { socket } = useAuthContext();
  const [messages, setMessages] = useState([]);
  const [systemTyping, setSystemTyping] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [messageBody, setMessageBody] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const messageInputFieldRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const inputDisabled = initializing || submitting;
  const submissionDisabled = !messageBody?.trim() || submitting;

  const handleMessageBodyChange = (event) => {
    setMessageBody(event.target.value);
  };

  const getChatMessages = async () => {
    const messages = await api.chat.message.getAll();
    console.log('got messages', messages);
    setMessages(messages);
  };

  const submitMessage = async (message) => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      await api.chat.message.create({
        text: message,
      });
      setMessageBody('');
    }
    catch (err) {
      // const message = err?.response?.data?.message;
      setErrorMessage('UNKNOWN ERROR');
    }
    setSubmitting(false);
    focusInput();
  };

  const focusInput = () => {
    if (messageInputFieldRef.current) {
      console.log('focusing input', messageInputFieldRef);
      messageInputFieldRef.current.focus();
    }
    else {
      console.log('unable to focus input, setting timeout and trying again later');
      setTimeout(() => {
        focusInput();
      }, 100);
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      console.log('scrolling to bottom');
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
    else {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }

  useEffect(() => {
    if (!inputDisabled) {
      focusInput();
    }
  }, [inputDisabled])

  useEffect(() => {
    scrollToBottom();
  }, [messages])

  useEffect(() => {
    function onMessage({ message, chatSessionId }) {
      console.log('socket message received', { message, chatSessionId });
      setSystemTyping(false);
      const messageAlreadyAdded = !!messages.find(m => m.id === message.id);
      if (!messageAlreadyAdded) {
        setMessages(messages => ([...messages, message]));
      }
    }

    function onSystemTyping({ chatSessionId }) {
      setSystemTyping(true);
    }

    socket.on('message', onMessage);
    socket.on('system typing', onSystemTyping);

    return () => {
      socket.off('message', onMessage);
      socket.off('system typing', onSystemTyping);
    };
  }, [socket]);

  useEffect(() => {
    if (systemTyping) {
      scrollToBottom();
    }
  }, [systemTyping]);

  useEffect(() => {
    setInitializing(true);
    getChatMessages()
      .then(() => {
        setInitializing(false);
        focusInput();
      })
  }, []);

  const context = {
    errorMessage,
    initializing,
    submissionDisabled,
    messages,
    messageBody,
    inputDisabled,
    messageInputFieldRef,
    messagesContainerRef,

    setMessageBody,
    submitMessage,
    handleMessageBodyChange,
  };

  console.log('chat context', context);

  return (
    <ChatContext.Provider value={ context }>
      { children }
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext)
  if (context === undefined) {
    throw new Error(
      'UseChatContext must be used within a ChatProvider',
    )
  }
  return context
}
