import ReactMarkdown from 'react-markdown';
import { CopyBlock, dracula } from 'react-code-blocks';
import _ from 'lodash';

// import { useChatContext } from '../context.js';

import './index.scss';

function ChatMessage({
  message,
}) {
  // const {} = useChatContext();
  const {
    id,
    properties,
  } = message;

  const propertiesByName = _.keyBy(properties, 'name');

  const {
    replyType,
    messageText,
  } = propertiesByName;

  return (
    <>
      <div
        key={ id }
        className={ `chat-message-container ${ replyType.value === 'user' ? 'user' : 'system' }` }
      >
        <div className="chat-message-inner">
          <div className="chat-message-text">
            { replyType.value === 'user'
              ? messageText.value
              : (
                <ReactMarkdown
                  children={messageText.value}
                  components={{
                    code: (config) => {
                      const { node, inline, className, children, ...props } = config;
                      const language = className?.replace('language-', '');
                      const isInline = inline !== undefined ? inline : !/\n/.test(children);

                      if (isInline) {
                        return <code className={ `${className ? className : ''} inline` } {...props}>{children}</code>;
                      } else {
                        return (
                          <CopyBlock
                            text={String(children).replace(/\n$/, '')}
                            language={language || 'text'}  // Ensure default language is set
                            wrapLines={false}
                            theme={dracula}
                          />
                        );
                      }
                    },
                  }}
                />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatMessage;
