import { useState, useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import { AuthProvider } from './state/providers/auth';
// import StandardPage from './sharedComponents/StandardPage';

import Main from './components/Main';

import { themeConfig } from './config';

const theme = createTheme(themeConfig);

// const ErrorView = () =>
//   <StandardPage>
//     <h2>Uh oh!</h2>
//     <p>It looks like an error occurred somewhere. Our team is taking a look. Please reload the page, clear your cookies, reset your cache, and try again. We appreciate your patience.</p>
//   </StandardPage>

function App() {
  const [token, updateToken] = useState(null);

  const resetToken = useCallback(newToken => {
    if (!newToken) {
      localStorage.removeItem('token');
      updateToken(null);
    }
    else {
      localStorage.setItem('token', newToken);
      updateToken(newToken);
    }
  }, [updateToken]);


  return (
    <BrowserRouter>
      <AuthProvider resetToken={ resetToken } token={ token }>
        <ThemeProvider theme={ theme }>
          <div className="App">
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <Main />
            </Box>
          </div>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
