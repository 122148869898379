import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';

import { useChatContext, ChatProvider } from './context.js';

import './index.scss';

function ChatContainer() {
  return (
    <ChatProvider>
      <Chat />
    </ChatProvider>
  );
}

function Chat() {
  const {
    initializing,
  } = useChatContext();

  return (
    <div className="chat-messages-window">
      {
        initializing
        ? null
        : (<ChatMessages />)
      }
      <ChatInput />
    </div>
  );
}

export default ChatContainer;
